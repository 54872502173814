<template>
  <div>
    <normalPart :cont="cont"></normalPart>
  </div>
</template>

<script>
// @ is an alias to /src
import NormalPart from '../components/normalPart'
export default {
  name: 'FirstPart',
  components: { NormalPart },
  data() {
    return {
      cont: {
        title: '教学综合能力评测',
        desc:
          '  本评测模块旨在考核被评测者是否具备担任网络培训教学综合能力。 <br/>  通过本评测，一方面能依靠科学的手段和方法，对被评测者的教育学基础知识及教学基本技能的掌握情况，以及在教学案例中的应用熟练程度，以及教学必备知识的了解情况进行综合的测量和评定。此外，通过评测也能促使参与者在教学能力与相关知识水平上不断提升；另一方面，本评测还能协助教育培训相关用人单位完成对从业人员的甄选、选拔晋升、组织诊断以及培训和发展员工等工作，提升企业和社会组织的运营效率。',
        target:
          '  本评测主要适用对象是从事网络培训领域工作，并致力于在网络培训领域获得进一步发展的人员，帮助他们获得相关领域能力的标准评定。同时也适用于企业对员工的招聘与管理，做为评价的依据。<br/> 另外本评测以现代教育学以及教育心理学为基础，并覆盖职业道德规范、教育政策法规等内容，以选择题在线答题的形式，通过知识点考察、情景模拟提问等方法，对被测评者进行考核。',
        methods: `
       本项评测要求参与者采用在线答题的方式进行，共200道选择题，用时约120分钟，正确率超过60%即视为通过。被评测者在参加评测期间应该遵守考试纪律，独立完成评测内容。考试时不得向他人求助，也不能通过搜索引擎、书本等工具查阅题目相关内容。
       `,

        symble: [
          `
          教学基本能力<br />
          1.教育学基本知识<br />
          2.理解国家实施素质教育的基本要求<br />
          3.掌握在教育中开展素质教育的途径和方法<br />
          4.具有较高的文化素养<br />
         `,
          ` <br />
          职业道德<br />
          1.教师的职业道德规范<br />
          2.教育相关法律法规<br />
          3.教师的职业行为规范以及需要处理的主要关系<br />
`,
          `    <br />教育学
 <br />1.理解国家实施素质教育的基本要求
<br />2.掌握在教育中开展素质教育的途径和方法`,
          ` <br />
          教育心理学<br />
          1.学生心理<br />
          2.教师心理<br />
          3.学习心理<br />
          4.教学心理<br />`,
        ],
      },
    }
  },
}
</script>
<style lang="less" scoped></style>
